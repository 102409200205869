import request from '@/api/request';

export interface GetServicesParams {
  page: number;
  page_size: number;
}

export function getServices(params: GetServicesParams): Promise<any> {
  // @ts-ignore
  return request.post('/v1/service/list', { page: 1, page_size: 10, ...params });
}
