import { notification } from 'antd';
import _ from 'lodash';
import type { AxiosError, AxiosResponse } from 'axios';
import axios from 'axios';

import { message } from '@/components/StaticAnt';

import { EECode, ECode } from './errorCode';

/**
 * @param response
 * @returns
 */
export function successHandler(response: AxiosResponse<any>) {
  return response.data;
}

function errorHandler(error: AxiosError) {
  const errMsgFromServer = _.get(error, 'response.data.detail.message');

  if (errMsgFromServer) {
    message.error(errMsgFromServer);
  } else {
    const errMsg = _.get(error, 'response.data.message') || _.get(error, 'response.statusText') || '请求失败';
    message.error(`【${_.get(error, 'response.status')}】${errMsg}`);
  }

  return Promise.reject(error);
}

const authorizationBearerFailed = (error: any) => {
  // 401一秒后跳转到登录页
  if (error?.response?.status === 401 && !import.meta.env.DEV) {
    // TODO
  }
  // 特殊状态码 没有具体code
  if (error?.response?.status === 422) {
    message.error('数据格式错误');
  }

  return Promise.reject(error);
};

const requestConfig = {
  timeout: 2 * 60 * 1000,
  baseURL: '/api',
};

const request = axios.create(requestConfig);
export const plainRequest = axios.create(requestConfig);

request.interceptors.response.use(successHandler, errorHandler);
request.interceptors.response.use(undefined, authorizationBearerFailed);
plainRequest.interceptors.response.use(undefined, errorHandler);
plainRequest.interceptors.response.use(undefined, authorizationBearerFailed);

export default request;
