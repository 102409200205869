import type { LoaderFunctionArgs } from 'react-router-dom';

export async function rootLoader({ params }: LoaderFunctionArgs) {
  try {
    // TODO
    return {
      data: {},
    };
  } catch (err) {
    return null;
  }
}
