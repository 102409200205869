import type { LoaderFunctionArgs } from 'react-router-dom';

import queryClient from '@/constant/queryClient';
import { serviceKey } from '@/constant/query-key-factories/service';
import { getServices } from '@/services/service.service';

import { getLogs } from '../services/log.service';
import { logKey } from '../constant/query-key-factories';

export async function logsLoader({ params, request }: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const search = new URLSearchParams(url.search);
  const entries = search.entries();
  const searchParams = Object.fromEntries(entries);

  try {
    const logs = await queryClient.fetchQuery(logKey.list(searchParams), () => getLogs(searchParams));
    const services = await queryClient.fetchQuery(
      serviceKey.list({
        page: 1,
        page_size: 10000,
      }),
      () => getServices({ page: 1, page_size: 10000 }),
    );

    return {
      logs,
      services,
    };
  } catch (err) {
    return null;
  }
}
