import type { MenuDataItem } from '@ant-design/pro-components';
import { ProLayout } from '@ant-design/pro-components';
import { Outlet, useNavigate, useLocation, useMatches } from 'react-router-dom';
import type { ReactNode } from 'react';
import { useEffect } from 'react';
import _ from 'lodash';

import type { Match } from '@/components/Breadcrumb';
import ErrorBoundary from '@/components/ErrorBoundary';

export default () => {
  const location = useLocation();
  const navigate = useNavigate();

  const matches = useMatches() as Match[];
  const title = _.chain(matches)
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match) => match.handle.crumb!(match.data))
    .last()
    .value() as string;

  useEffect(() => {
    // 如果是根路径，跳转到首页
    if (location.pathname === '/' || location.pathname === '') {
      navigate('/dashboard');
    }
  }, [location.pathname, navigate]);

  const route = {
    path: '/',
    children: [
      {
        path: '/dashboard',
        name: '仪表盘',
      },
      {
        path: '/organization',
        name: '组织',
      },
    ],
  };

  return (
    <ProLayout
      className="layout-wrapper h-screen bg-white"
      breakpoint={false}
      layout="top"
      route={route}
      title="埋点分析平台"
      pageTitleRender={() => title}
      onMenuHeaderClick={() => {
        window.location.href = '/dashboard';
      }}
      menuItemRender={(item: MenuDataItem, dom: ReactNode) => (
        <span
          onClick={() => {
            navigate(item.path || '/');
          }}
        >
          {dom}
        </span>
      )}
      token={{
        pageContainer: {
          paddingBlockPageContainerContent: 0,
          paddingInlinePageContainerContent: 0,
        },
        bgLayout: '#fff',
        sider: {
          colorMenuBackground: '#FFF',
          colorTextMenu: 'var(--color-text-base)',
          colorTextMenuActive: 'var(--color-text-base)',
          colorTextMenuItemHover: 'var(--color-primary)',
          colorTextMenuSelected: 'var(--color-primary)',
          colorBgMenuItemSelected: '#F4F5F9',
        },
      }}
      // @ts-ignore
      ErrorBoundary={ErrorBoundary}
      location={location}
    >
      <div className="flex flex-col p-4">
        <Outlet />
      </div>
    </ProLayout>
  );
};
