import loadable from '@loadable/component';
import { Spin } from 'antd';

import { rootLoader } from '@/loaders/root.loader';

import MainLayout from './layouts/Main';
import { logsLoader } from './loaders/logs.loader';

const moduleSpin = {
  fallback: <Spin className="w-full mt-[30vh]" />,
};

const Dashboard = loadable(() => import('./pages/dashboard'), moduleSpin);
const Organization = loadable(() => import('./pages/organization'), moduleSpin);

export default [
  {
    path: '/',
    element: <MainLayout />,
    // 此ID可以用于在路由中获取loader中的数据
    id: 'root',
    loader: rootLoader,
    name: '首页',
    handle: {
      crumb: () => {
        return '首页';
      },
    },
    children: [
      {
        path: 'dashboard',
        id: 'dashboard',
        element: <Dashboard />,
        loader: logsLoader,
        name: '仪表盘',
        handle: {
          crumb: () => {
            return '仪表盘';
          },
        },
      },
      {
        path: 'organization',
        element: <Organization />,
        handle: {
          crumb: () => {
            return 'Organization';
          },
        },
      },
    ],
  },
];
