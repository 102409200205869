import request from '@/api/request';

export interface GetLogListParams {
  page: number;
  page_size: number;
}

export function getLogs(params: GetLogListParams): Promise<any> {
  // @ts-ignore
  return request.post('/v1/log/list', { page: 1, page_size: 10, ...params });
}
